import React, { useEffect, useState } from 'react';
import Template from '../../base/template';
import apiService from '../../data/api.service';
import endpoints from '../../api.router.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

const Postes = () => {
  const [roles, setRoles] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [selectedPoste, setSelectedPoste] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [postes, setPostes] = useState([]);

  const { get, post } = apiService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesResult = await get(endpoints.getRoles);
        const relationshipsResult = await get(endpoints.getPosteRoles); // Ensure consistent naming
        const postesResult = await get(endpoints.getPostes);

        if (rolesResult.success) {
          setRoles(rolesResult.data);
        }
        if (relationshipsResult.success) {
          setRelationships(relationshipsResult.data);
        }
        if (postesResult.success) {
          setPostes(postesResult.data);
        }
      } catch (error) {
        toast.error('Failed to fetch data');
      }
    };

    fetchData();
  }, []);

  const handleShowModal = (poste) => {
    setSelectedPoste(poste);

    // Ensure relationships is defined before using it
    if (relationships.length > 0) {
      const posteRoles = relationships
        .filter((rel) => rel.posteId === poste.id)
        .map((rel) => roles.find((role) => role.id === rel.roleId))
        .filter(Boolean); // Filter out any undefined roles
      setSelectedRoles(posteRoles);
    }

    setShowModal(true);
  };

  const handleSaveRoles = async () => {
    if (!selectedPoste) return;

    const newRelationships = selectedRoles.map((role) => ({
      roleId: role.id,
      posteId: selectedPoste.id,
    }));

    try {
      await post(endpoints.updateRelationships, {
        roleId: selectedRoles.map((role) => role.id),
        posteId: selectedPoste.id,
      });

      toast.success('Rôles mis à jour avec succès');
      setShowModal(false);

      // Refresh relationships
      const relationshipsResult = await get(endpoints.getPosteRoles); // Consistency in endpoint
      if (relationshipsResult && relationshipsResult.data) {
        setRelationships(relationshipsResult.data);
      }
    } catch (error) {
      console.error('Error updating roles:', error);
      toast.error('Failed to update roles.');
    }
  };

  const handleRoleChange = (selectedOptions) => {
    const rolesSelected = selectedOptions.map((option) => ({
      id: option.value,
      name: option.label,
    }));
    setSelectedRoles(rolesSelected);
  };

  return (
    <Template title="Postes">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nom</th>
                  <th>Rôles</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {postes.map((poste) => (
                  <tr key={poste.id}>
                    <td>{poste.id}</td>
                    <td>{poste.name}</td>
                    <td>
                      {relationships
                        .filter((rel) => rel.posteId === poste.id)
                        .map(
                          (rel) =>
                            roles.find((role) => role.id === rel.roleId)?.name
                        )
                        .filter(Boolean)
                        .join(', ')}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleShowModal(poste)}
                      >
                        Gérer les rôles
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Gérer les rôles de {selectedPoste?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            isMulti
            options={roles.map((role) => ({
              value: role.id,
              label: role.name,
            }))}
            value={selectedRoles.map((role) => ({
              value: role.id,
              label: role.name,
            }))}
            onChange={handleRoleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSaveRoles}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
    </Template>
  );
};

export default Postes;
