import React from "react";

export default function SidebarDropdown({ index, title, icone, children }) {
  return (
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        data-bs-target={`#${index}-nav`}
        data-bs-toggle="collapse"
        href="#"
      >
        <i className={icone} />
        <span>
          {title}
        </span>
        <i className="bi bi-chevron-down ms-auto" />
      </a>
      <ul
        id={`${index}-nav`}
        className="nav-content collapse"
        data-bs-parent="#sidebar-nav"
      >
        {children}
      </ul>
    </li>
  );
}
