import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Template from '../../base/template';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import endpoints from '../../api.router.config';
import apiService from '../../data/api.service';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

const ManageRoles = () => {
  const [roles, setRoles] = useState([]);
  const [roleToUpdate, setRoleToUpdate] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const { get, post, put, del } = apiService();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      const result = await get(endpoints.getRoles);
      if (result.success) {
        setRoles(result.data);
      } else {
        toast.error('Failed to fetch roles');
      }
    };

    fetchRoles();
  }, []);

  const onSubmit = async (data) => {
    let result;
    if (roleToUpdate) {
      result = await put(`${endpoints.updateRole}/${roleToUpdate.id}`, data);
    } else {
      result = await post(endpoints.addRole, data);
    }

    if (result.success) {
      toast.success(`Role ${roleToUpdate ? 'updated' : 'added'} successfully`);
      reset();
      setRoleToUpdate(null);
      const updatedRoles = await get(endpoints.getRoles);
      if (updatedRoles.success) {
        setRoles(updatedRoles.data);
      }
    } else {
      toast.error(result.error);
    }
  };

  const handleDelete = async () => {
    console.log(roleToDelete);
    const result = await del(`${endpoints.deleteRole(roleToDelete.id)}`);

    console.log(result);
    if (result.success) {
      toast.success('Role deleted successfully');
      const updatedRoles = await get(endpoints.getRoles);
      if (updatedRoles.success) {
        setRoles(updatedRoles.data);
      }
    } else {
      toast.error(result.error);
    }
    setShowDeleteDialog(false);
    setRoleToDelete(null);
  };

  return (
    <Template title="Manage Roles">
      <div className="container mt-5">
        <div className="card shadow-lg p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="roleName" className="form-label">
                Role Name
              </label>
              <input
                type="text"
                className="form-control"
                id="roleName"
                {...register('name', { required: true })}
                defaultValue={roleToUpdate ? roleToUpdate.name : ''}
              />
              {errors.name && (
                <span className="text-danger">Role name is required</span>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {roleToUpdate ? 'Update' : 'Add'} Role
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4">
          <h3>Roles List</h3>
          {roles.length > 0 ? (
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role) => (
                  <tr key={role.id}>
                    <td>{role.id}</td>
                    <td>{role.name}</td>
                    <td className="d-flex gap-4">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => setRoleToUpdate(role)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setRoleToDelete(role);
                          setShowDeleteDialog(true);
                        }}
                      >
                        <i className="bi bi-trash3"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>

      <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the role "{roleToDelete?.name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Template>
  );
};

export default ManageRoles;
