import React from 'react';
import { Link } from 'react-router-dom';

export default function SidebarDropdownItem({ url, icone, label }) {
  return (
    <li>
      <Link to={url}>
        <i className={`bi ${icone}`} />
        <span>{label}</span>
      </Link>
    </li>
  );
}
