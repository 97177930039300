import React from 'react'
import { getInfosAuth } from '../../auth/verifyAuth'
import logOut from '../../auth/logout'

export default function HeaderProfile() {
    const auth = getInfosAuth()
    
  return (
    <li className="nav-item dropdown pe-3">

          <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
            <span className="d-none d-md-block dropdown-toggle ps-2">{auth.employeeName || "Non identifié"}</span>
          </a>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            
            <li>
              <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                <i className="bi bi-person"></i>
                <span>Mon Profil</span>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            <li>
              <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                <i className="bi bi-gear"></i>
                <span>Mon compte</span>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            <li>
              <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                <i className="bi bi-gear"></i>
                <span>Changer mot de passe</span>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            <li>
              <button className="dropdown-item d-flex align-items-center" onClick={logOut} >
                <i className="bi bi-box-arrow-right"></i>
                <span>Se déconnecter</span>
              </button>
            </li>

          </ul>
        </li>
  )
}
