import React from 'react';
import ListUsers from './views/users/listUsers';
import CreateUser from './views/users/createUser';
import ManageRoles from './views/roles/manageRoles';
import Postes from './views/postes/postes';
import ManageUsers from './views/users/manageUsers';

const ErrorRoute = React.lazy(() => import('./views/errorPage'));
const LoginRoute = React.lazy(() => import('./views/login'));
const HomeRoute = React.lazy(() => import('./views/home'));
const Dashboard = React.lazy(() => import('./views/dashboard'));
const CreateMission = React.lazy(() =>
  import('./views/missions/agenceMissionCreate')
);

const routes = [
  { path: '/error', exact: true, name: 'Error', element: ErrorRoute },
  { path: '/login', exact: true, name: 'Login', element: LoginRoute },
  { path: '/home', exact: true, name: 'Home', element: HomeRoute },
  { path: '/', exact: true, name: 'Dash', element: Dashboard },
  {
    path: '/missions/agence/create',
    exact: true,
    name: 'CreateMission',
    element: CreateMission,
  },
  { path: '/users', exact: true, name: 'ListUsers', element: ListUsers },
  {
    path: '/users/create',
    exact: true,
    name: 'CreateUser',
    element: CreateUser,
  },
  { path: '/roles', exact: true, name: 'ListRoles', element: ManageRoles },
  { path: '/postes', exact: true, name: 'Postes', element: Postes },
  {
    path: '/userm',
    exact: true,
    name: 'Users Management',
    element: ManageUsers,
  },
];

export default routes;
