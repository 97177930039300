import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SidebarLink({ url, icone, label }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleClick = () => {
    setIsCollapsed(false);
  };

  return (
    <li className="nav-item">
      <Link
        className={`nav-link ${isCollapsed ? 'collapsed' : ''}`}
        to={url}
        onClick={handleClick}
      >
        <i className={icone} />
        <span>{label}</span>
      </Link>
    </li>
  );
}