
import React, { useState } from "react";

export default function Logo() {

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    document.body.classList.toggle("toggle-sidebar", !sidebarVisible);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <a href="index.html" className="logo d-flex align-items-center">
        <img src= {window.location.origin+"/assets/img/logo.png"} alt="logo" />
        <span className="d-none d-lg-block">ERP</span>
      </a>
      <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar} />
    </div>
  );
}
