import endpoints from "../api.router.config";

function deleteFromCookies() {
  try {
    const cookies = [
      "token",
      "roles",
      "employeeId",
      "employeeName",
      "agenceId",
    ];
    const deleteCookie = name => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${endpoints.domaine_name};`;
    };
    cookies.forEach(cookie => deleteCookie(cookie));
    return true;
  } catch (error) {
    return false;
  }
}

function deleteFromLocalStorage() {
  try {
    const items = ["token", "roles", "employeeId", "employeeName", "agenceId"];

    items.forEach(item => {
      localStorage.removeItem(item);
      console.log(`Removed ${item} from localStorage`);
    });

    return true;
  } catch (error) {
    return false;
  }
}

const logOut = () => {
    deleteFromCookies()
    deleteFromLocalStorage()
  window.location.href = '/login';
};

export default logOut;
