import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Template from '../../base/template';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import endpoints from '../../api.router.config';
import apiService from '../../data/api.service';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [agences, setAgences] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { get, post, put } = apiService();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResult = await get(endpoints.getUsers);
        if (usersResult) {
          setUsers(usersResult.data);
        }
        // Fake data for companies and agences
        const fakeCompanies = [
          { id: 1, name: 'Guepex' },
          { id: 2, name: 'Yalidine' },
        ];
        const fakeAgences = [
          { id: 10101, name: 'Tiaret' },
          { id: 10102, name: 'Tlemcen' },
        ];
        setCompanies(fakeCompanies);
        setAgences(fakeAgences);
      } catch (error) {
        toast.error('Failed to fetch data');
      }
    };
    fetchData();
  }, []);

  const handleShowModal = (user = null) => {
    setSelectedUser(user);
    if (user) {
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('email', user.email);
      setValue('phone', user.phone);
      setValue('companyId', user.companyId);
      setValue('agenceId', user.agenceId);
      setValue('employeeId', user.employeeId);
      setValue('posteId', user.posteId);
    } else {
      reset();
    }
    setShowModal(true);
  };

  const handleSaveUser = async (data) => {
    console.log(data);
    try {
      if (selectedUser) {
        const res = await put(`${endpoints.updateUser(selectedUser.id)}`, data);
        if (res.success) {
          toast.success('User updated successfully');
        } else {
          toast.error(res.error);
        }
      } else {
        const res = await post(`${endpoints.addUser}`, data);
        if (res.success) {
          toast.success('User created successfully');
        } else {
          toast.error(res.error);
        }
      }
      setShowModal(false);
      const usersResult = await get(endpoints.getUsers);
      if (usersResult) {
        setUsers(usersResult.data);
      }
    } catch (error) {
      toast.error('Failed to save user');
    }
  };

  const handleCompanyChange = (selectedOption) => {
    setValue('companyId', selectedOption ? selectedOption.value : '');
  };

  const handleAgenceChange = (selectedOption) => {
    setValue('agenceId', selectedOption ? selectedOption.value : '');
  };

  return (
    <Template title="Users">
      <div className="container mt-5">
        <div className="d-flex justify-content-end mb-3">
          <Button onClick={() => handleShowModal()}>Add User</Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Company</th>
                  <th>Agence</th>
                  <th>Employee ID</th>
                  <th>Poste</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>
                      {companies.find((c) => c.id === user.companyId)?.name}
                    </td>
                    <td>{agences.find((a) => a.id === user.agenceId)?.name}</td>
                    <td>{user.employeeId}</td>
                    <td>{user.posteId}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleShowModal(user)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser ? 'Edit User' : 'Add User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleSaveUser)}>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    {...register('firstName', { required: true })}
                  />
                  {errors.firstName && (
                    <span className="text-danger">First name is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    {...register('lastName', { required: true })}
                  />
                  {errors.lastName && (
                    <span className="text-danger">Last name is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    {...register('email', { required: true })}
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>
              </div>
              {!selectedUser && (
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      {...register('password', { required: true })}
                    />
                    {errors.password && (
                      <span className="text-danger">Password is required</span>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    {...register('phone', { required: true })}
                  />
                  {errors.phone && (
                    <span className="text-danger">Phone is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="companyId" className="form-label">
                    Company
                  </label>
                  <Select
                    id="companyId"
                    options={companies.map((company) => ({
                      value: company.id,
                      label: company.name,
                    }))}
                    onChange={handleCompanyChange}
                    defaultValue={
                      selectedUser?.companyId
                        ? {
                            value: selectedUser.companyId,
                            label: companies.find(
                              (company) => company.id === selectedUser.companyId
                            )?.name,
                          }
                        : null
                    }
                  />
                  {errors.companyId && (
                    <span className="text-danger">Company is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="employeeId" className="form-label">
                    Employee ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="employeeId"
                    {...register('employeeId', {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                  {errors.employeeId && (
                    <span className="text-danger">Employee ID is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="agenceId" className="form-label">
                    Agence
                  </label>
                  <Select
                    id="agenceId"
                    options={agences.map((agence) => ({
                      value: agence.id,
                      label: agence.name,
                    }))}
                    onChange={handleAgenceChange}
                    defaultValue={
                      selectedUser?.agenceId
                        ? {
                            value: selectedUser.agenceId,
                            label: agences.find(
                              (agence) => agence.id === selectedUser.agenceId
                            )?.name,
                          }
                        : null
                    }
                  />
                  {errors.agenceId && (
                    <span className="text-danger">Agence is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="posteId" className="form-label">
                    Poste
                  </label>
                  <select
                    className="form-control"
                    id="posteId"
                    {...register('posteId', {
                      required: true,
                      valueAsNumber: true,
                    })}
                  >
                    <option value="">Select Poste</option>
                    <option value="1">Poste 1</option>
                    <option value="2">Poste 2</option>
                  </select>
                  {errors.posteId && (
                    <span className="text-danger">Poste is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Template>
  );
};

export default Users;
