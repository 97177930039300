import React from "react";
import Header from "./header/header";
import Sidebar from "./sidebar/sidebar";

export default function Template({ title, children }) {
    return (
      <div>
        <Header />
        <Sidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>{title}</h1>
            
          </div>
          {children}
        </main>
      </div>
    );
  }