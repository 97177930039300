import React from 'react';
import Logo from './logo';
import HeaderNav from './headerNav';


const Header = () => {
  return (
    
      <header id="header" className="header fixed-top d-flex align-items-center" >
        <Logo />
        <HeaderNav/>
      </header>
    
  );
};

export default Header;

