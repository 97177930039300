import React from "react";
import HeaderProfile from "./headerProfile";
import HeaderNotification from "./headerNotification";

export default function HeaderNav() {
  return (
    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">
        <HeaderNotification />
        <HeaderProfile />
      </ul>
    </nav>
  );
}
