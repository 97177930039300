import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Template from '../../base/template';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import endpoints from '../../api.router.config';
import apiService from '../../data/api.service';
import { toast } from 'react-toastify';
import Select from 'react-select';

const CreateUser = () => {
  const [userToUpdate, setUserToUpdate] = useState({});
  const [companies, setCompanies] = useState([]);
  const [agences, setAgences] = useState([]);
  const [postes, setPostes] = useState([]);
  const [filteredPostes, setFilteredPostes] = useState([]);
  const { get, post, put } = apiService();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userIdToUpdate = queryParams.get('update');

  useEffect(() => {
    const fetchData = async () => {
      if (userIdToUpdate) {
        const result = await get(`${endpoints.getUser(userIdToUpdate)}`);
        if (result) {
          setUserToUpdate(result.data);
        }
      }
    };
    fetchData();
  }, [userIdToUpdate]);

  useEffect(() => {
    const fetchAgences = async () => {
      const result = await get('https://base.tydal-dev.cloud/agences/get/list');
      if (result.success) {
        setAgences(result.data);
      }
    };

    const fetchCompanies = async () => {
      const result = await get(
        'https://base.tydal-dev.cloud/companies/get/list'
      );
      if (result.success) {
        setCompanies(result.data);
      }
    };

    const fetchPostes = async () => {
      const result = await get('https://base.tydal-dev.cloud/postes/get/full');
      if (result.success) {
        setPostes(result.data);
      }
    };

    fetchAgences();
    fetchCompanies();
    fetchPostes();
  }, []);

  useEffect(() => {
    if (userToUpdate.companyId) {
      const companyPostes = postes.filter(
        (post) => post.companyId === userToUpdate.companyId
      );
      setFilteredPostes(companyPostes);
    }
  }, [userToUpdate.companyId, postes]);

  const handleCompanyChange = (selectedOption) => {
    setValue('companyId', selectedOption ? selectedOption.value : '');
    const companyPostes = postes.filter(
      (post) => post.companyId === selectedOption.value
    );
    setFilteredPostes(companyPostes);
  };

  const handleAgenceChange = (selectedOption) => {
    setValue('agenceId', selectedOption ? selectedOption.value : '');
  };

  const handlePosteChange = (selectedOption) => {
    setValue('posteId', selectedOption ? selectedOption.value : '');
  };

  const onSubmit = async (data) => {
    if (userIdToUpdate) {
      const res = await put(`${endpoints.updateUser(userIdToUpdate)}`, data);
      if (res.success) {
        toast.success('User updated successfully');
        navigate('/users');
      } else {
        toast.error(res.error);
      }
    } else {
      const res = await post(`${endpoints.addUser}`, data);
      if (res.success) {
        toast.success('User created successfully');
        navigate('/users');
      } else {
        toast.error(res.error);
      }
    }
  };

  if (userIdToUpdate && !userToUpdate) {
    return <div>Loading...</div>;
  }
  if (userIdToUpdate && !userToUpdate.id) {
    return <div>User not found</div>;
  }

  return (
    <Template
      title={`${
        userIdToUpdate ? "Modifier l'utilisateur " : 'Ajouter un utilisateur'
      }`}
    >
      <div className="container mt-5">
        <div className="card shadow-lg p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    {...register('firstName', { required: true })}
                    defaultValue={userToUpdate.firstName}
                  />
                  {errors.firstName && (
                    <span className="text-danger">First name is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    {...register('lastName', { required: true })}
                    defaultValue={userToUpdate.lastName}
                  />
                  {errors.lastName && (
                    <span className="text-danger">Last name is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    {...register('email', { required: true })}
                    defaultValue={userToUpdate.email}
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>
              </div>
              {!userIdToUpdate && (
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      {...register('password', { required: true })}
                    />
                    {errors.password && (
                      <span className="text-danger">Password is required</span>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    {...register('phone', { required: true })}
                    defaultValue={userToUpdate.phone}
                  />
                  {errors.phone && (
                    <span className="text-danger">Phone is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="companyId" className="form-label">
                    Company
                  </label>
                  <Select
                    id="companyId"
                    options={companies.map((company) => ({
                      value: company.id,
                      label: company.name,
                    }))}
                    onChange={handleCompanyChange}
                    defaultValue={
                      userToUpdate.companyId
                        ? {
                            value: userToUpdate.companyId,
                            label: companies.find(
                              (company) => company.id === userToUpdate.companyId
                            )?.name,
                          }
                        : null
                    }
                  />
                  {errors.companyId && (
                    <span className="text-danger">Company is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="employeeId" className="form-label">
                    Employee ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="employeeId"
                    {...register('employeeId', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    defaultValue={userToUpdate.employeeId}
                  />
                  {errors.employeeId && (
                    <span className="text-danger">Employee ID is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="agenceId" className="form-label">
                    Agence
                  </label>
                  <Select
                    id="agenceId"
                    options={agences.map((agence) => ({
                      value: agence.agenceId,
                      label: agence.name,
                    }))}
                    onChange={handleAgenceChange}
                    defaultValue={
                      userToUpdate.agenceId
                        ? {
                            value: userToUpdate.agenceId,
                            label: agences.find(
                              (agence) =>
                                agence.agenceId === userToUpdate.agenceId
                            )?.name,
                          }
                        : null
                    }
                  />
                  {errors.agenceId && (
                    <span className="text-danger">Agence is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="posteId" className="form-label">
                    Poste
                  </label>
                  <Select
                    id="posteId"
                    options={filteredPostes.map((poste) => ({
                      value: poste.id,
                      label: poste.name,
                    }))}
                    onChange={handlePosteChange}
                    defaultValue={
                      userToUpdate.posteId
                        ? {
                            value: userToUpdate.posteId,
                            label: filteredPostes.find(
                              (poste) => poste.id === userToUpdate.posteId
                            )?.name,
                          }
                        : null
                    }
                  />
                  {errors.posteId && (
                    <span className="text-danger">Poste is required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
};

export default CreateUser;
