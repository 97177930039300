import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Template from '../../base/template';
import apiService from '../../data/api.service';
import endpoints from '../../api.router.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';

const ListUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPosteId, setFilterPosteId] = useState('');
  const [filterActive, setFilterActive] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [agences, setAgences] = useState([]);
  const [postes, setPostes] = useState([]);
  const [roleMap, setRoleMap] = useState({});
  const { get, del, post } = apiService();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const usersResult = await get(endpoints.getUsers);
      const rolesResult = await get(endpoints.getExtraRoles);
      const allRolesResult = await get(endpoints.getRoles);
      const companiesResult = await get(
        'https://base.tydal-dev.cloud/companies/get/list'
      );

      const agencesResult = await get(
        'https://base.tydal-dev.cloud/agences/get/list'
      );
      const postesResult = await get(
        'https://base.tydal-dev.cloud/postes/get/full'
      );

      if (
        usersResult &&
        rolesResult &&
        allRolesResult &&
        companiesResult.success &&
        agencesResult.success &&
        postesResult.success
      ) {
        const roleMap = allRolesResult.data.reduce((acc, role) => {
          acc[role.id] = role.name;
          return acc;
        }, {});
        setRoleMap(roleMap);

        const companyMap = companiesResult?.data.reduce((acc, company) => {
          acc[company.id] = company.name;
          return acc;
        }, {});

        const agenceMap = agencesResult.data.reduce((acc, agence) => {
          acc[agence.agenceId] = agence.name;
          return acc;
        }, {});

        const posteMap = postesResult.data.reduce((acc, poste) => {
          acc[poste.id] = poste.name;
          return acc;
        }, {});

        const usersWithFullName = usersResult.data.map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
          extraRoles: rolesResult.data
            .filter((role) => role.userId === user.id)
            .map((role) => roleMap[role.roleId]),
          companyName: companyMap[user.companyId],
          agenceName: agenceMap[user.agenceId],
          posteName: posteMap[user.posteId],
        }));
        setUsers(usersWithFullName);
        setFilteredUsers(usersWithFullName);
        setRoles(allRolesResult.data);
        setCompanies(companiesResult?.data);
        setAgences(agencesResult.data);
        setPostes(postesResult.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = users;

    if (searchTerm) {
      filtered = filtered.filter(
        (user) =>
          user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterPosteId) {
      filtered = filtered.filter(
        (user) => user.posteId === parseInt(filterPosteId)
      );
    }

    if (filterActive) {
      filtered = filtered.filter((user) =>
        filterActive === 'Yes' ? user.isActive : !user.isActive
      );
    }

    setFilteredUsers(filtered);
  }, [searchTerm, filterPosteId, filterActive, users]);

  const handleAddNewUser = () => {
    navigate('/users/create');
  };

  const handleDelete = async () => {
    console.log(userToDelete);
    const res = await del(endpoints.deleteUser(userToDelete.id));
    if (res.success) {
      toast.success('Utilisateur supprimé avec succès');
      const result = await get(endpoints.getUsers);
      if (result) {
        const usersWithFullName = result.data.map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }));
        setUsers(usersWithFullName);
        setFilteredUsers(usersWithFullName);
      }
    } else {
      toast.error(res.error);
    }
    setShowDeleteDialog(false);
    setUserToDelete(null);
  };

  const handleOpenRoleModal = (user) => {
    setSelectedUser(user);
    setSelectedRoles(
      user.extraRoles.map((roleName) => {
        const roleId = Object.keys(roleMap).find(
          (key) => roleMap[key] === roleName
        );
        return { value: +roleId, label: roleName };
      })
    );
    console.log({ selectedRoles });
    setShowRoleModal(true);
  };

  const handleRoleChange = async () => {
    const roles = selectedRoles.map((role) => role.value);

    const res = await post(endpoints.updateExtraRoles, {
      userId: selectedUser.id,
      roleId: roles,
    });
    if (res.success) {
      toast.success('Rôles mis à jour avec succès');
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? { ...user, extraRoles: roles } : user
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      setShowRoleModal(false);
    } else {
      toast.error(res.error);
    }
  };

  return (
    <Template title="Liste des utilisateurs">
      <div className="mt-4">
        <div className="d-flex justify-content-between mb-3">
          <button className="btn btn-success" onClick={handleAddNewUser}>
            Ajouter un nouveau utilisateur
          </button>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher par nom, nom d'utilisateur ou email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              value={filterPosteId}
              onChange={(e) => setFilterPosteId(e.target.value)}
            >
              <option value="">Filtrer par Poste</option>
              {[...new Set(users.map((user) => user.posteId))].map(
                (posteId) => (
                  <option key={posteId} value={posteId}>
                    {postes.find((poste) => poste.id === posteId)?.name ||
                      posteId}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              value={filterActive}
              onChange={(e) => setFilterActive(e.target.value)}
            >
              <option value="">Filtrer par statut actif</option>
              <option value="Yes">Actif</option>
              <option value="No">Inactif</option>
            </select>
          </div>
        </div>
        {filteredUsers.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Id</th>
                  <th>Nom complet</th>
                  <th>Nom d'utilisateur</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Entreprise</th>
                  <th>ID employé</th>
                  <th>Agence</th>
                  <th>Poste</th>
                  <th>Est actif</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.fullName}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.companyName}</td>
                    <td>{user.employeeId}</td>
                    <td>{user.agenceName}</td>
                    <td>{user.posteName}</td>
                    <td>{user.isActive ? 'Yes' : 'No'}</td>
                    <td className="d-flex gap-4">
                      <a
                        href={`/users/create?update=${user.id}`}
                        className="btn btn-primary btn-sm mr-2 d-flex align-items-center justify-content-center"
                      >
                        <i className="bi bi-pencil-square"></i>
                      </a>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setUserToDelete(user);
                          setShowDeleteDialog(true);
                        }}
                      >
                        <i className="bi bi-trash3"></i>
                      </button>
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => handleOpenRoleModal(user)}
                      >
                        <i className="bi bi-pencil"></i> Extra rôles
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>Chargement...</div>
        )}
      </div>

      <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer l'utilisateur "
          {userToDelete?.fullName}" ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteDialog(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRoleModal} onHide={() => setShowRoleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Modifier les rôles de {selectedUser?.fullName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            isMulti
            options={roles.map((role) => ({
              value: role.id,
              label: role.name,
            }))}
            value={selectedRoles}
            onChange={(selected) => setSelectedRoles(selected)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRoleModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleRoleChange}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
    </Template>
  );
};

export default ListUsers;
