import React from 'react';
import SidebarLink from './sidebarLink';
import SidebarDropdown from './sidebarDropdown';
import SidebarDropdownItem from './sidebarDropdownItem';

export default function Sidebar() {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <SidebarLink url="/home" icone="bi bi-grid" label="Dashboard" />

        {/* <li className="nav-heading">Agence</li> */}

        <SidebarDropdown title="Utilisateurs" icone="bi-people" index="users">
          <SidebarDropdownItem
            url="/users/"
            icone="bi-circle"
            label="Liste des utilisateurs"
          />

          <SidebarDropdownItem
            url="/users/create"
            icone="bi-circle"
            label="Ajouter un utilisateur"
          />
        </SidebarDropdown>

        <SidebarLink url="/roles  " icone="bi-person-workspace" label="Roles" />
        <SidebarLink url="/postes  " icone="bi-folder-fill" label="Postes" />

        {/* <li className="nav-heading">Navette</li>*/}
      </ul>
    </aside>
  );
}
