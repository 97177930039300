import axios from 'axios';
import { getToken } from '../auth/verifyAuth';

const apiService = () => {
  /*** recuperation de données */

  const get = async (url) => {
    try {
      const token = getToken();
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur inconnue',
          };
        }

        if (error.response.status === 401) {
          window.location.href = '/login';
          return null;
        }

        if (error.response.status === 403) {
          window.location.href = '/error';
          return null;
        }

        if (error.response.status === 500) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur interne du serveur',
          };
        }
      }

      return {
        success: false,
        error: "Une erreur inattendue s'est produite.",
      };
    }
  };

  /*** creation data */
  const post = async (url, data) => {
    try {
      const token = getToken();
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur inconnue',
          };
        }

        if (error.response.status === 401) {
          window.location.href = '/login';
          return null;
        }

        if (error.response.status === 403) {
          window.location.href = '/error';
          return null;
        }

        if (error.response.status === 500) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur interne du serveur',
          };
        }
      }

      return {
        success: false,
        error: "Une erreur inattendue s'est produite.",
      };
    }
  };

  /**** update */
  const put = async (url, data) => {
    try {
      const token = getToken();
      const response = await axios.put(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur inconnue',
          };
        }

        if (error.response.status === 401) {
          window.location.href = '/login';
          return null;
        }

        if (error.response.status === 403) {
          window.location.href = '/error';
          return null;
        }

        if (error.response.status === 500) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur interne du serveur',
          };
        }
      }

      return {
        success: false,
        error: "Une erreur inattendue s'est produite.",
      };
    }
  };

  const del = async (url) => {
    try {
      const token = getToken();
      const response = await axios.delete(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur inconnue',
          };
        }

        if (error.response.status === 401) {
          window.location.href = '/login';
          return null;
        }

        if (error.response.status === 403) {
          window.location.href = '/error';
          return null;
        }

        if (error.response.status === 500) {
          return {
            success: false,
            error: error.response.data.error || 'Erreur interne du serveur',
          };
        }
      }

      return {
        success: false,
        error: "Une erreur inattendue s'est produite.",
      };
    }
  };

  return { get, post, put, del };
};

export default apiService;
