import { get } from 'react-hook-form';

const AUTH_URL = 'https://auth.tydal-dev.cloud/api';
const LOGISTIC_URL = 'https://logistic.tydal-dev.cloud';
const PARC_URL = 'https://parc.tydal-dev.cloud';
const GPS_URL = 'https://gps.tydal-dev.cloud';
const RH_URL = 'https://rh.tydal-dev.cloud';
const BASE_URL = 'https://base.tydal-dev.cloud';
const ERP_URL = 'https://base.tydal-dev.cloud';

const DOMAINE_NAME = 'tydal-dev.cloud'; /*** a changer au deployement */

const AGENCE_URL = `${LOGISTIC_URL}/agences`;
const CAMION_URL = `${LOGISTIC_URL}/camions`;
const CHAUFFEUR_URL = `${LOGISTIC_URL}/chauffeurs`;
const CONVOYEUR_URL = `${LOGISTIC_URL}/chauffeurs`;

const MISSION_URL = `${LOGISTIC_URL}/missions`;
const SAC_URL = `${LOGISTIC_URL}/sacs`;
const LOST_FOUND_URL = `${LOGISTIC_URL}/lostFound`;

const endpoints = {
  auth_url: AUTH_URL,
  logistic_url: LOGISTIC_URL,
  parc_url: PARC_URL,
  gps_url: GPS_URL,
  rh_url: RH_URL,
  base_url: BASE_URL,
  erp_url: ERP_URL,
  domaine_name: DOMAINE_NAME,

  authLogin: `${AUTH_URL}/auth/login`,
  /********************************* */
  /******* agences ***************** */
  /********************************* */
  agenceGetList: `${AGENCE_URL}/get/list`,
  agenceGetFull: `${AGENCE_URL}/get/full`,

  /********************************* */
  /******* chauffeurs ************** */
  /********************************* */

  chauffeurGetList: `${CHAUFFEUR_URL}/get/list`,

  /********************************* */
  /******* convoyeurs ************** */
  /********************************* */
  convoyeurGetList: `${CONVOYEUR_URL}/get/list`,

  /********************************* */
  /******* camions ***************** */
  /********************************* */

  camionGetList: `${CAMION_URL}/get/list`,

  /********************************* */
  /********** missions ************* */
  /********************************* */
  /*** missions agence */

  agenceMissionCreate: `${MISSION_URL}/agence/create`,
  agenceMissionPost: `${MISSION_URL}/agence/post`,
  agenceMissionUpdate: (missionId) =>
    `${MISSION_URL}/agence/update/${missionId}`,
  agenceMissionPut: `${MISSION_URL}/agence/put`,
  agenceMissionConfirm: (missionId) =>
    `${MISSION_URL}/agence/confirm/${missionId}`,
  agenceMissionDelete: (missionId) =>
    `${MISSION_URL}/agence/delete/${missionId}`,
  agenceCamionReception: `${MISSION_URL}/agence/scan/camion`,
  agenceFinTransation: (missionId) =>
    `${MISSION_URL}/agence/finish/transaction/${missionId}`,
  agenceMissionGetCurrent: `${MISSION_URL}/agence/get/current`,
  agenceMissionGetFinished: `${MISSION_URL}/agence/get/finished`,
  agenceMissionGetCurrentSingle: (missionId) =>
    `${MISSION_URL}/agence/get/current/${missionId}`,
  /*** missions navette */
  navetteMissionGetCurrent: `${MISSION_URL}/navette/get/current`,
  navetteMissionPauseCreate: `${MISSION_URL}/navette/pause/create`,
  navetteMissionPauseEnd: `${MISSION_URL}/navette/pause/end`,
  /*** missions admin */
  adminMissionGetCurrent: `${MISSION_URL}/admin/get/current`,
  adminMissionGetFinished: `${MISSION_URL}/admin/get/finished`,
  adminMissionGetDeleted: `${MISSION_URL}/admin/get/deleted`,
  adminMissionGetInPause: `${MISSION_URL}/admin/get/in/pause`,
  adminMissionGetSingle: (missionId) =>
    `${MISSION_URL}/admin/get/single/${missionId}`, //a modifier backend
  adminMissionSearch: `${MISSION_URL}/admin/search`,
  adminMissionDelete: `${MISSION_URL}/admin/delete` /*** POST */,

  /************************************* */
  /******* sacs ************************ */
  /************************************* */

  agenceSacExpedition: `${SAC_URL}/agence/expedition`,
  agenceSacReception: `${SAC_URL}/agence/reception`,
  agenceSacDeleteExpedition: (sacId) => `${SAC_URL}/expedition/delete/${sacId}`,

  navetteSacReception: `${SAC_URL}/navette/reception`,

  adminGet: `${SAC_URL}/admin/get`,
  adminGetByAgence: `${SAC_URL}/admin/get/by/agence`,

  /*********************************** */
  /******** lost and found *********** */
  /*********************************** */

  createSacFound: (sacId) => `${LOST_FOUND_URL}/found/create/${sacId}`,
  createSacLost: (sacId) => `${LOST_FOUND_URL}/lost/create/${sacId}`,

  /*********************************** */
  /******** Users *********** */
  /*********************************** */

  getUsers: `${AUTH_URL}/user`,
  addUser: `${AUTH_URL}/user`,
  updateUser: (userId) => `${AUTH_URL}/user/${userId}`,
  getUser: (userId) => `${AUTH_URL}/user/${userId}`,
  deleteUser: (userId) => `${AUTH_URL}/user/${userId}`,

  /*********************************** */
  /******** Roles *********** */
  /*********************************** */

  getRoles: `${AUTH_URL}/roles`,
  addRole: `${AUTH_URL}/roles`,
  updateRole: (roleId) => `${AUTH_URL}/roles/${roleId}`,
  getRole: (roleId) => `${AUTH_URL}/roles/${roleId}`,
  deleteRole: (roleId) => `${AUTH_URL}/roles/${roleId}`,

  /*********************************** */
  /******** Postesroles *********** */
  /*********************************** */

  getPosteRoles: `${AUTH_URL}/posteroles`,
  updateRelationships: `${AUTH_URL}/posteroles`,

  /*********************************** */
  /******** ExtraRoles *********** */
  /*********************************** */
  getExtraRoles: `${AUTH_URL}/extrarole`,
  updateExtraRoles: `${AUTH_URL}/extrarole`,

  /*********************************** */
  /******** Postes *********** */
  /*********************************** */
  getPostes: `${BASE_URL}/postes/get/list`,
};

export default endpoints;
